<template>
  <el-card>
    <DisposeCountTitle :titleName="titleName" :btnList="btnList" @btnSelFn='btnSelFn'></DisposeCountTitle>
    <div ref="echarts1" class="main">
      内容
    </div>
  </el-card>
</template>

<script>
import DisposeCountTitle from "./DisposeCountTitle.vue";
import {
  misreAnomalyStatistics
} from "../../../api/chuzhijilu/index";
export default {
  components: {
    DisposeCountTitle
  },
  data() {
    return {
      titleName: '错误表述统计（近一年）',
      btnList: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      let data = {}
      let res = await misreAnomalyStatistics(data)
      if (res.data.code == 200) {
        // let dataList = res.data.data?.misre || []
        // let timeArr = []
        // let list = []//原始数据集合
        // let nameList = []
        // let allData = []
        // dataList.forEach(v => {
        //   timeArr.push(v.time)
        //   list.push(...v.data)
        // });
        // nameList = Array.from(new Set(list.map(v => v.label)))
        // nameList.forEach(v => {
        //   allData.push(
        //     {
        //       name: v,
        //       list:[...(list.filter(e => e.label == v)).count]
        //     }
        //   )
        // })
        // console.log(allData);
        let timeArr = res.data.data.misre.map(v => v.time)
        let list1 = res.data.data.misre.map(v => v.data[0].count)
        let list2 = res.data.data.misre.map(v => v.data[1].count)
        let obj = {
          timeArr,
          list1,
          list2
        }
        this.setEcharts(obj)
      }
    },
    setEcharts(obj) {
      let myChart = this.$echarts.init(this.$refs.echarts1)
      let option = {
        color: ["#FF6961", "#2FC25B"],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "2%",
          right: "5%",
          bottom: "13%",
          top: "30px",
          containLabel: true,
        },

        legend: {
          show: true,
          left: "center",
          top: "bottom",
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            fontSize: 12,
            height: 8,
            rich: {
              a: {
                verticalAlign: "bottom",
              },
            },
          },
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: obj.timeArr
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              type: 'dashed', // dotted 虚线
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            smooth: true, // 是否曲线
            name: "错误表述总数", // 图例对应类别
            data: obj.list1, // 纵坐标数据
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,  //右
                y: 0,  //下
                x2: 0,  //左
                y2: 1,  //上
                colorStops: [
                  {
                    offset: 0,
                    color: '#FF6058' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#fff' // 100% 处的颜色
                  }
                ]
              },
            },
          },
          {
            type: "line",
            smooth: true,
            name: "错误表述处置数量",
            data: obj.list2,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,  //右
                y: 0,  //下
                x2: 0,  //左
                y2: 1,  //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: '#2FC25B' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#fff' // 100% 处的颜色
                  }
                ]
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    btnSelFn(index) {
      console.log(index, '主页面');
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 380px;
}
</style>