<template>
  <el-card>
    <DisposeCountTitle :titleName="titleName" :btnList="btnList" @btnSelFn='btnSelFn'></DisposeCountTitle>
    <div class="card-main-content" v-loading="loading">
      <vue-seamless-scroll :data="publishTop10" :class-option="this.$main.classOption()">
        <div class="main-item" v-for="item,index in publishTop10" :key="index">
          <div class="item-top">
            <div class="index" :class="{'index1':index==0,'index2':index==1,'index3':index==2}">{{index+1}}</div>
            <div class="name-num">
              <div class="name">{{item.name}}</div>
              <div class="num">{{item.value}}</div>
            </div>
          </div>
          <div class="item-bottom">
            <el-progress :percentage="parseFloat(((item.value)/(publishTop10[0].value)*100).toFixed(2))" :color="proColor(index)"
                         :show-text="false"></el-progress>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </el-card>
</template>

<script>
import DisposeCountTitle from "./DisposeCountTitle.vue";
import {
  harmfulAccountStat
} from "../../../api/chuzhijilu/index";
export default {
  components: {
    DisposeCountTitle
  },
  data() {
    return {
      titleName: '发布账号TOP10',
      btnList: [],
      publishTop10: [
        
      ],
      loading:true
    }
  },
  mounted(){
    this.getDataList()
  },
  methods: {
    async getDataList(){
      this.loading = true
      let res = await harmfulAccountStat()
      if(res.data.code==200){
        console.log(res.data.data,222);
        this.publishTop10 = res.data.data
        this.loading = false
      }
    },
    btnSelFn(index) {
      console.log(index, '主页面');
    },
    //滚动条颜色
    proColor(item) {
      let obj = {
        0: '#F36950',
        1: '#FFD366',
        2: '#3FCD7E',
        3: '#409EFF',
      }
      if (item >= 3) item = 3
      return obj[item]
    },
  }
}
</script>

<style lang="scss" scoped>
.card-main-content {
  max-height: 365px;
  min-height: 365px;
  overflow: hidden;
  .main-item {
    margin-bottom: 26px;
    .item-top {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 18px;
      .index {
        width: 16px;
        margin-right: 10px;
        font-size: 14px;
      }
      .name-num {
        display: flex;
        justify-content: space-between;
        width: 96%;
        .name {
          font-size: 14px;
          font-weight: 400;
          color: #5a6474;
        }
        .num {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .item-bottom {
      padding-left: 24px;
    }
  }
}
.index1 {
  color: #d0021b;
}
.index2 {
  color: #ff2a39;
}
.index3 {
  color: #ff6a2c;
}
</style>