<template>
  <div class="page">
    <div class="page-title">
      <div class="title-name">处置结果统计</div>
    </div>
    <!-- 总统计 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <Total></Total>
      </el-col>
    </el-row> 
    <!-- 问题平台 -->
    <el-row :gutter="20">
      <el-col :span="12">
        <Problem></Problem>
      </el-col>
      <el-col :span="12">
        <ProblemTrend></ProblemTrend>
      </el-col>
    </el-row>
    <!-- 问题平台 -->
    <el-row :gutter="20">
      <el-col :span="12">
        <Harmful></Harmful>
      </el-col>
      <el-col :span="12">
        <PublishTop10></PublishTop10>
      </el-col>
    </el-row>
    <!-- 错误表述 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <ErrorLine></ErrorLine>
      </el-col>
    </el-row>
    <!-- 错误类型 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <ErrorColum></ErrorColum>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Total from "./compoents/Total";
import Problem from "./compoents/Problem";
import ProblemTrend from "./compoents/ProblemTrend";
import Harmful from "./compoents/Harmful";
import PublishTop10 from "./compoents/PublishTop10";
import ErrorLine from "./compoents/ErrorLine";
import ErrorColum from "./compoents/ErrorColum";
export default { 
  components: {
    Total,
    Problem,
    ProblemTrend,
    Harmful,
    PublishTop10,
    ErrorLine,
    ErrorColum
  },
}
</script>

<style lang="scss" scoped>
.page {
  .page-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 24px 0;
    .title-name {
      font-size: 26px;
      font-weight: 600;
      color: #1a1a1a;
    }
  }
  .el-row {
    margin-bottom: 12px;
  }
}

</style>