<template>
  <el-card>
    <DisposeCountTitle :titleName="titleName" :btnList="btnList" @btnSelFn='btnSelFn'></DisposeCountTitle>
    <div ref="echarts1" class="main">
      内容
    </div>
  </el-card>
</template>

<script>
import DisposeCountTitle from "./DisposeCountTitle.vue";
import {
  abnormalAccountTrend
} from "../../../api/chuzhijilu/index";
export default {
  components: {
    DisposeCountTitle
  },
  data() {
    return {
      titleName: '问题平台增长趋势（近一年）',
      btnList: []
    }
  },
  mounted() {
    this.getDataList()
    this.setEcharts()
  },
  methods: {
    async getDataList() {
      let res = await abnormalAccountTrend()
      if (res.data.code == 200) {
        let data = res.data.data
        let timeArr = []
        let listData = []
        let nameList = []
        let allData = []
        // let color = [
        //   "#6596f9",
        //   "#6bdfaf",
        //   "#6f80a1",
        //   "#f7c125",
        //   "#7b6cfd",
        //   "#80d6f8",
        //   "#9d6bc0",
        //   "#f69647",
        //   '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc',"#6596f9",
        //   "#6bdfaf",
        //   "#6f80a1",
        //   "#f7c125",
        //   "#7b6cfd",
        //   "#80d6f8",
        //   "#9d6bc0",
        //   "#f69647",
        //   '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        data.forEach(v => {
          timeArr.push(v.date)
          listData.push(...v.list)
        });
        console.log('listdata',listData);
        nameList = data[0].list.map(v => v.name)
        nameList.forEach((v, i) => {
          allData.push(
            {
              name: v,
              list: [...listData.filter(e => e.name == v).map(e => e.value)],
              // color: color[i]
            }
          )
        })
        console.log(allData);
        let seriesData = allData.map(v => {
          return {
            type: "line",
            smooth: true, // 是否曲线
            name: v.name, // 图例对应类别
            data: v.list, // 纵坐标数据
            // areaStyle: {
            //   color: {
            //     type: 'linear',
            //     x: 0,  //右
            //     y: 0,  //下
            //     x2: 0,  //左
            //     y2: 1,  //上
            //     colorStops: [
            //       {
            //         offset: 0.1,
            //         color: v.color // 0% 处的颜色
            //       },
            //       {
            //         offset: 1,
            //         color: '#fff' // 100% 处的颜色
            //       }
            //     ]
            //   },
            // },
          }
        })
        console.log(timeArr, seriesData);
        this.setEcharts({ timeArr, seriesData })
      }
    },
    setEcharts({ timeArr, seriesData }) {
      let myChart = this.$echarts.init(this.$refs.echarts1)
      let option = {
        
        tooltip: {
          trigger: "axis",
           appendToBody: true,
          // formatter: function (params) {
          //   //console.log(params);
          //   var tip = "";
          //   if (params !== null && params.length > 0) {
          //     tip += params[0].name + '<br/>';
          //     for (let i = 0; i < params.length; i++) {
          //       if (i % 3 === 0) {
          //         tip += params[i].marker + params[i].seriesName + ": " + params[i].value + "<br/>";
          //       } else {
          //         tip += params[i].marker + params[i].seriesName + ": " + params[i].value + "&nbsp;&nbsp;";
          //       }
          //     }
          //     return tip;
          //   }
          // }

        },
        grid: {
          left: "2%",
          right: "5%",
          bottom: "5%",
          top: "80px",
          // containLabel: true,
        },

        legend: {
          show: true,
          left: "right",
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            fontSize: 12,
            height: 8,
            rich: {
              a: {
                verticalAlign: "bottom",
              },
            },
          },
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: timeArr,
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              type: 'dashed', // dotted 虚线
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: seriesData
        // [
        //   {
        //     type: "line",
        //     smooth: true, // 是否曲线
        //     name: "魅力值", // 图例对应类别
        //     data: [4000, 6000, 7000, 2000, 5000, 7000, 9000], // 纵坐标数据
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,  //右
        //         y: 0,  //下
        //         x2: 0,  //左
        //         y2: 1,  //上
        //         colorStops: [
        //           {
        //             offset: 0.1,
        //             color: '#5090FF' // 0% 处的颜色
        //           },
        //           {
        //             offset: 1,
        //             color: '#1057E500' // 100% 处的颜色
        //           }
        //         ]
        //       },
        //     },
        //   },
        //   {
        //     type: "line",
        //     smooth: true,
        //     name: "能力值",
        //     data: [1000, 4000, 5000, 6000, 3000, 8000, 7000],
        //     areaStyle: {
        //       color: {
        //         type: 'linear',
        //         x: 0,  //右
        //         y: 0,  //下
        //         x2: 0,  //左
        //         y2: 1,  //上
        //         colorStops: [
        //           {
        //             offset: 0.1,
        //             color: '#01B3E4' // 0% 处的颜色
        //           },
        //           {
        //             offset: 1,
        //             color: '#86DCF300' // 100% 处的颜色
        //           }
        //         ]
        //       },
        //     },
        //   },
        // ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    btnSelFn(index) {
      console.log(index, '主页面');
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 350px;
}
</style>