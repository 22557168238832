<template>
  <el-card>
    <DisposeCountTitle :titleName="titleName" :btnList="btnList" @btnSelFn='btnSelFn'></DisposeCountTitle>
    <div ref="echarts1" class="main">
      内容
    </div>
  </el-card>
</template>

<script>
import DisposeCountTitle from "./DisposeCountTitle.vue";
import {
  harmfulPlatformStat
} from "../../../api/chuzhijilu/index";
export default {
  components: {
    DisposeCountTitle
  },
  data() {
    return {
      titleName: '问题账号类型分布（有害信息）',
      btnList: ['近30天', '近半年', '近一年']
    }
  },
  mounted() {
    this.btnSelFn(0)
  },
  methods: {
    async getDataList(data){
      // let data = {startTime:'2022-07-12 00:00:00',endTime:'2023-08-11 00:00:00'}
      let res = await harmfulPlatformStat(data)
      if(res.data.code==200){
        console.log(res.data.data,111);
        this.setEcharts(res.data.data)
      }
    },
    setEcharts(data) {
      let myChart = this.$echarts.init(this.$refs.echarts1)
      let option = {
        color: [
          "#6596f9",
          "#6bdfaf",
          "#6f80a1",
          "#f7c125",
          "#7b6cfd",
          "#80d6f8",
          "#9d6bc0",
          "#f69647",
        ],
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} {d}%",
        },
        toolbox: {
          show: true,
        },
        legend: {
          bottom: '3%',
          left: 'center',
          icon: "circle", //图例形状【circle\rect\roundRect\triangle\diamond\pin\arrow\none】
          textStyle: {
            color: "#8C8C8C",
          },
          // height:150
        },
        series: [
          {
            type: "pie",
            radius: [0, 80],
            center: ['50%', '40%'],
            label: {
              normal: {
                show: true,
                formatter: "{d}%",
              },
              emphasis: {
                show: true,
              },
            },
            data,
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    btnSelFn(index) {
      console.log(index, '主页面');
      let nowTime = new Date().getTime()
      let Time30 = nowTime - 1000 * 60 * 60 * 24 * 30
      let Time180 = nowTime - 1000 * 60 * 60 * 24 * 180
      let Time365 = nowTime - 1000 * 60 * 60 * 24 * 365
      let timeArr = [
        {
          startTime:this.$main.timeCycle(Time30,'yyyy-MM-dd HH:mm:ss'),
          endTime:this.$main.timeCycle(nowTime,'yyyy-MM-dd HH:mm:ss')
        },
        {
          startTime:this.$main.timeCycle(Time180,'yyyy-MM-dd HH:mm:ss'),
          endTime:this.$main.timeCycle(nowTime,'yyyy-MM-dd HH:mm:ss')
        },
        {
          startTime:this.$main.timeCycle(Time365,'yyyy-MM-dd HH:mm:ss'),
          endTime:this.$main.timeCycle(nowTime,'yyyy-MM-dd HH:mm:ss')
        },
      ]
      this.getDataList(timeArr[index])
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 365px;
}
</style>