<template>
  <div class="analyse-container" >
    <div class="analyse-container-img">
      <img src="@/assets/img/gjh/weitu.png" style="width:200px"/>
      <p style="margin-top:20px">{{desc}}暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
props:{
    desc:{
        type:String,
        default:''
    }
}
}
</script>

<style lang="scss" scoped>
 .analyse-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    // margin-top: 60px;
    // text-align: center;
    padding-top: 60px;
    .analyse-container-img {
      text-align: center;
  }
  
  .analyse-container-img p {
    font-size: 14px;
    color: #999999;
  }
  }
</style>