<template>
  <el-card>
    <DisposeCountTitle
      :titleName="titleName"
      :btnList="btnList"
      @btnSelFn="btnSelFn"
    ></DisposeCountTitle>
    <div class="flex-box">
      <!-- 这三模块 可以用v-for来做 可是我取不到动态ref -->
      <div class="block" :style="{ background: dataPeizhi[1].boxBg }" v-loading="chartLoading">
        <div class="left" ref="ChartHX_1"></div>
        <div class="right">
          <div class="one" :style="{ color: dataPeizhi[1].startColor }">
            {{ dataPeizhi[1].count }}
          </div>
          <div class="two">{{ dataPeizhi[1].text }}</div>
        </div>
      </div>
      <div class="block" :style="{ background: dataPeizhi[2].boxBg }" v-loading="chartLoading">
        <div class="left" ref="ChartHX_2"></div>
        <div class="right">
          <div class="one" :style="{ color: dataPeizhi[2].startColor }">
            {{ dataPeizhi[2].count }}
          </div>
          <div class="two">{{ dataPeizhi[2].text }}</div>
        </div>
      </div>
      <div class="block" :style="{ background: dataPeizhi[3].boxBg }" v-loading="chartLoading">
        <div class="left" ref="ChartHX_3"></div>
        <div class="right">
          <div class="one" :style="{ color: dataPeizhi[3].startColor }">
            {{ dataPeizhi[3].count }}
          </div>
          <div class="two">{{ dataPeizhi[3].text }}</div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { overallStatistics } from "@/api/chuzhijilu/index";
import DisposeCountTitle from "./DisposeCountTitle.vue";
import {
  getHXoptions,
  getHXdataPeizhi,
  btnList,
  buttonAndValue,
} from "../options/index.js";
export default {
  components: {
    DisposeCountTitle,
  },
  data() {
    return {
      titleName: "总处置结果统计（全部信息）",
      btnList: btnList["总处置结果统计（全部信息）"],
      dataPeizhi: getHXdataPeizhi,
      chartLoading:false
    };
  },
  created() {},
  mounted() {
    this.getInfo(0);
    
  },
  methods: {
    btnSelFn(index) {
      this.chartLoading = true
      this.getInfo(index);
    },
    async getInfo(index) {
      const { titleName } = this;
      try {
        const Res = await overallStatistics(buttonAndValue({ titleName, index }));
      this.setEchart(Res?.data?.data || {});
      } catch (error) {
        this.setEchart()
      } 
      
      this.chartLoading =false
    },
    async setEchart({abnormalPlatform,harmful,misre}={}) {
      const obj ={1:abnormalPlatform,2:harmful,3:misre}

      const chartNameList = [1, 2, 3].map((key) => "ChartHX_" + key);
      chartNameList.forEach((chartName, index) => {
        // const peizhiData = this.dataPeizhi[index + 1]
        const requestObj = obj[index +1]
        this.dataPeizhi[index + 1].value = requestObj?.proportion || 0
        this.dataPeizhi[index + 1].count = requestObj?.total || 0
        const myChart = this.$echarts.init(this.$refs[chartName]);
        myChart.setOption(getHXoptions(this.dataPeizhi[index + 1]));
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-around;
  .block {
    width: 360px;
    height: 120px;
    .left {
      width: 50%;
      height: 100%;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      height: 100%;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .one {
        font-size: 40px;
      }
      .two {
        font-size: 16px;
        color: #595959;
      }
    }
  }
}
</style>
