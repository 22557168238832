import * as echarts from "echarts";
export const btnList = {
  '总处置结果统计（全部信息）': [
    // "全部", "今天", "昨天"
  ],
};
const valueOfText = {
  全部: 0,
  今天: 1,
  昨天: 2,
  近30天: 3,
  近半年: 4,
  近一年: 5,
};
export const buttonAndValue = ({ titleName, index, keyName = "period" }) => ({
  [keyName]: valueOfText[btnList[titleName][index]],
});
export const getHXdataPeizhi = {
  1: {
    value: 0,
    startColor: "#3CA3FF",
    endColor: "#53CCFF",
    boxBg: "#ecf6ff",
    count: 0,
    text: "问题平台",
  },
  2: {
    value: 0,
    startColor: "#FF7771",
    endColor: "#FFB7AF",
    boxBg: "#fff2f1",
    count: 0,
    text: "有害信息",
  },
  3: {
    value: 0,
    startColor: "#50E89E",
    endColor: "#50E89E",
    boxBg: "#eefdf6",
    count: 0,
    text: "错误表述信息",
  },
};
export const getHXoptions = ({ value, startColor, endColor }) => {
  let option = {
    title: {
      text: "处置率",
      textStyle: {
        color: "#909090",
        fontSize: 14,
      },
      subtext: value + "%",
      subtextStyle: {
        color: "#01c4a3",
        fontSize: 20,
      },
      itemGap: 10, // 主副标题距离
      left: "center",
      top: "25%",
    },
    angleAxis: {
      max: 100, // 满分
      // clockwise: false, // 逆时针
      // 隐藏刻度线
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    radiusAxis: {
      type: "category",
      // 隐藏刻度线
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    polar: {
      center: ["50%", "50%"],
      radius: "170%", //图形大小
    },
    series: [
      {
        type: "bar",
        data: [
          {
            name: "作文得分",
            value,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: startColor,
                  },
                  {
                    offset: 1,
                    color: endColor,
                  },
                ]),
              },
            },
          },
        ],
        coordinateSystem: "polar",
        roundCap: true,
        barWidth: 5,
        barGap: "-100%", // 两环重叠
        z: 2,
      },
      {
        // 灰色环
        type: "bar",
        data: [
          {
            value: 100,
            itemStyle: {
              color: "#fff",
            },
          },
        ],
        coordinateSystem: "polar",
        roundCap: true,
        barWidth: 5,
        barGap: "-100%", // 两环重叠
        z: 1,
      },
    ],
  };
  return option;
};
export function getDuiDieHook(resData = []) {
  const expandData = resData.reduce(
    (prev, v) =>
      (prev = [
        ...prev,
        ...v.platformTypesList.map((e) => ({ ...e, value: v.value })),
      ]),
    []
  );
  const platformNameList = Array.from(
    new Set(expandData.map((v) => v.platformTypeName))
  );
  const wantData = platformNameList.map((name) => ({
    name,
    data: expandData
      .filter((v) => v.platformTypeName == name)
      .map((e) => e.platformNum),
  }));
  const jiegou = ({ name = "", data = [] }) => ({
    name,
    type: "bar",
    stack: "total",
    label: {
      // show: true
    },
    emphasis: {
      focus: "series",
    },
    data,
  });
  return {
    nameList: resData.map((v) => v.value) || [],
    seriesData: wantData.map((v) => jiegou(v)) || [],
  };
}
export function getDuiDieoptions({ nameList = [], seriesData = [] }) {
  let option = {
    tooltip: {
      appendToBody: true,
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      x: "center", // 图例水平居中
      bottom: "-1%",
      // left:'auto'
    },
    grid: {
      left: "20%",
      right: "15%",
      top: "5%",
      bottom: "13%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "#E4E6EC",
        },
      },
    },
    yAxis: {
      type: "category",
      data: nameList,
      axisTick: {
        show: false,
      },
    },
    series: seriesData,
  };
  return option;
}
