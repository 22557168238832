<template>
  <el-card >
    <DisposeCountTitle
      :titleName="titleName"
      :btnList="btnList"
      @btnSelFn="btnSelFn"
    ></DisposeCountTitle>
    <div ref="echarts1" class="main"  ></div>
  </el-card>
</template>

<script>
import { getDuiDieoptions,getDuiDieHook } from "../options/index";
import { cwlxfb } from "@/api/chuzhijilu/index";
import DisposeCountTitle from "./DisposeCountTitle.vue";
import Weitu from './Weitu.vue';
export default {
  components: {
    DisposeCountTitle,Weitu
  },
  data() {
    return {
      titleName: "错误类型分布图（近一年）",
      btnList: [],
      chartLoading: true,
      seriesData:[]
    };
  },
  mounted() {
    this.getInfo();
    // this.setEcharts()
  },
  methods: { 
    async getInfo() {
      // period:3 表示近一年
      const Res = await cwlxfb({ period: 3 });
      const resData = Res?.data?.data?.misre || [];
      await this.setEcharts(getDuiDieHook(resData));
      this.chartLoading = false;
    },
    setEcharts({ nameList = [], seriesData = [] }) {
      this.seriesData = seriesData
      let myChart = this.$echarts.init(this.$refs.echarts1);
      const option = getDuiDieoptions({ nameList, seriesData });
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    btnSelFn(index) {
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 380px;
} 

</style>
