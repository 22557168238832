<template>
  <div>
    <TitleCard>
      <template v-slot:title>
        <Title>{{titleName}}</Title>
      </template>
      <template v-slot:com>
        <ButtonSelection :BGColor="true" :buttonList="btnList" @setButtonSelectFn="setButtonSelectFn" />
      </template>
    </TitleCard>
  </div>
</template>

<script>
import Title from "../../../base/Title.vue";
import ButtonSelection from "../../../components/ButtonSelection.vue";
import TitleCard from "../../../components/TitleCard.vue";
export default {
  components: {
    Title,
    ButtonSelection,
    TitleCard
  },
  props:{
    titleName:{
      type:String,
      default:'默认title'
    },
    btnList:{
      type:Array,
      default:()=>[]
    },
  },
  methods: {
    setButtonSelectFn(index) {
      this.$emit('btnSelFn',index)
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonBox {
  .button-item {
    background: #f5f5f5;
  }
}
</style>